import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Title } from 'utils/title';
import LoadingPanel from 'components/LoadingPanel';
import { Attachment, Chat, Message, User } from '@progress/kendo-react-conversational-ui';
import { fetchApi } from '../../services/api';
import * as Moment from 'moment';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

type Params = {
    constructionTaskId: string;
};

export type TaskType = {
    id: number;
    title: string;
    contractorId: number;
    orderId: number;
    start: Date;
    end: Date;
    percentComplete: number;
    percentGoal: number;
    isExpanded: boolean;
    children?: TaskType[];
    details: string;
    week: number;
    drawBudget: number;
};

type AttachmentTemplateProps = {
    item: Attachment;
    onClick: () => void;
};

const AttachmentTemplate = ({ item, onClick }: AttachmentTemplateProps) => {
    if (item.contentType === 'checkin') {
        return (
            <div className="k-chat-bubble">
                <a href={`https://maps.google.com/maps?q=loc:${item.content}`} target="_blank" rel="noopener noreferrer">
                    Check-in at job site
                </a>
            </div>
        );
    } else if (item.contentType === 'checkout') {
        return (
            <div className="k-chat-bubble">
                <a href={`https://maps.google.com/maps?q=loc:${item.content}`} target="_blank" rel="noopener noreferrer">
                    Check-out at job site
                </a>
            </div>
        );
    } else if (item.contentType.match('^image/')) {
        return (
            <div className="k-card">
                <div className="k-card-body">
                    <img alt="" className="w-100" style={{ cursor: 'pointer' }} src={item.content} draggable={false} onClick={onClick} />
                </div>
            </div>
        );
    } else if (item.contentType === 'text/plain') {
        return item.content;
    } else {
        return null;
    }
};

const Task = () => {

    const [connection, setConnection] = useState<HubConnection>();
    const [loading, setLoading] = useState(true);
    const { constructionTaskId } = useParams<Params>();
    const [task] = useState<TaskType>();
    const [user, setUser] = useState<User>({ id: 0 });
    const [messages, setMessages] = useState<Message[]>([]);
    const [currentImage, setCurrentImage] = useState<Attachment | null>(null);

    const loadMessages = useCallback((taskId: string | undefined) => {
        fetchApi(`/api/ConstructionProject/TaskMessages/${taskId}`)
            .then((data: { User: any; Messages: any }) => {
                setUser(data.User);
                setMessages(
                    data.Messages.map((m: any) => ({
                        ...m,
                        timestamp: Moment.utc(m.timestamp).toDate(),
                    })),
                );
                setLoading(false);
            });
    }, []);

    const sendMessage = async (taskId: string | undefined, message: string) => {
        fetchApi(`/api/ConstructionProject/SendTaskMessage/${taskId}`, { Body: message }, 'post').then((data) => {
            // console.log(data);
        });
    };

    useEffect(() => {
        const builder = new HubConnectionBuilder()
          .withUrl("/hubs/taskChat", { accessTokenFactory: () => localStorage.getItem('ACCESS_TOKEN') || '' })
          .withAutomaticReconnect()
          .build();

        setConnection(builder)
      }, []);

      useEffect(() => {
        async function startConnection() {
            try {
                connection?.onreconnected(() => {
                    loadMessages(constructionTaskId);
                });

                connection?.on('typing', (data: { ConstructionProjectTaskMessageID: string }) => {
                    if (data.ConstructionProjectTaskMessageID === constructionTaskId) {
                        console.log('typing');
                    }
                });

                connection?.on('message', (data: { ConstructionProjectTaskMessageID: string }) => {
                    if (data.ConstructionProjectTaskMessageID === constructionTaskId) loadMessages(constructionTaskId);
                });

                connection?.on('logout', () => {
                    window.location.href = '/';
                });

                await connection?.start();
            } catch (error) {
                console.error(error);
            }
        }

        if (connection) {
            startConnection()
        }

        return () => {
            connection?.stop()
        }
    }, [connection, loadMessages, constructionTaskId]);

    useEffect(() => {
        loadMessages(constructionTaskId);
    }, [constructionTaskId, loadMessages]);

    const sortedMessages = [...messages];
    sortedMessages.sort((m1: Message, m2: Message) => (m1.timestamp?.getTime() ?? 0) - (m2.timestamp?.getTime() ?? 0));
    return (
        <div>
            <Title string={task?.title || 'Task'} />
            {loading && <LoadingPanel />}
            <Chat
                user={user}
                messages={sortedMessages}
                onMessageSend={(e) => {
                    if (e.message.text) {
                        sendMessage(constructionTaskId, e.message.text);
                        setMessages([...messages, e.message]);
                    }
                }}
                attachmentTemplate={({ item }: { item: Attachment }) => {
                    return (
                        <AttachmentTemplate
                            item={item}
                            onClick={() => {
                                setCurrentImage(item);
                            }}
                        />
                    );
                }}
            />
            {currentImage != null && (
                <Dialog onClose={() => setCurrentImage(null)} width={800}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 4 }}>
                        <Button icon="download" fillMode="flat" onClick={() => window.open(currentImage.content, '_blank')} />
                        <Button icon="x" fillMode="flat" onClick={() => setCurrentImage(null)} />
                    </div>

                    <img alt="" className="w-100" src={currentImage.content} draggable={false} />
                </Dialog>
            )}
        </div>
    );
};

export default Task;
