import * as React from 'react';
import LoadingPanel from '../LoadingPanel';
import { State as GridState, SortDescriptor, CompositeFilterDescriptor, toDataSourceRequestString, FilterDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridSortChangeEvent, GridDataStateChangeEvent, GridFilterChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { InvestorViewModel } from '../../utils/investor';
import { fetchApi } from '../../services/api';
import { drawRefreshButtonOnGridFooter, GridCellFilterType } from '../../utils/kendo';
import { InvestorTypeCell } from './InvestorTypeCell';
import InvestorNameCell from './InvestorNameCell';
import InvestorTypeFilterCell from './InvestorTypeFilterCell';
import { PhoneNumberCell } from '../PhoneNumberCell';
import { EmailAddressCell } from '../EmailAddressCell';
import ActiveFilterCell from '../ActiveFilterCell';
import { ActiveCell } from '../ActiveCell';
import { DateTimeCell } from '../DateTimeCell';
import { debounce } from 'ts-debounce';
import { Link } from 'react-router-dom';
import { paths } from '../../App';
import { Title } from '../../utils/title';
import { Button } from '@progress/kendo-react-buttons';
import { downloadIcon } from '@progress/kendo-svg-icons';

type Props = {}

type State = {
    isLoading: boolean;
    data: InvestorViewModel[];
    skip: number;
    take: number;
    sort: SortDescriptor[];
    filter: CompositeFilterDescriptor;
    dataState: GridState;
    totalRecords: number;
    isAdmin: boolean;
}

export default class Agent extends React.Component<Props, State> {

    private CONST_TAKE: number = 50;

    private CONST_DATASTATE_FILTER_DEFAULT = {
        logic: 'and',
    } as CompositeFilterDescriptor;

    private CONST_DATASTATE_DEFAULT = {
        take: this.CONST_TAKE, skip: 0,
        sort: [{ field: "FullName", dir: "asc" }],
        filter: { ...this.CONST_DATASTATE_FILTER_DEFAULT }
    } as GridState;

    private export: React.RefObject<ExcelExport>;

    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            data: [],
            totalRecords: 0,
            dataState: { ...this.CONST_DATASTATE_DEFAULT },
            skip: 0,
            take: 50,
            sort: [],
            filter: {
                logic: "and",
                filters: [{ field: "Active", operator: "eq", value: true }]
            } as CompositeFilterDescriptor,
            isAdmin: false
        }

        this.export = React.createRef();

        this.dataStateChange = this.dataStateChange.bind(this);
        this.download = this.download.bind(this);
    }

    private dataStateChange(changeEvent: GridDataStateChangeEvent) {
        this.setState({ dataState: changeEvent.dataState }, () => { this.fetch() });
    }

    sortChange = (event: GridSortChangeEvent) => {
        const dS = this.state.dataState;
        dS.sort = event.sort;
        this.setState({ dataState: dS }, () => { this.fetch() });
    }

    filterChange = (event: GridFilterChangeEvent) => {
        let state = this.state;
        if (event.filter) {
            //let filters = (event.filter.filters as FilterDescriptor[]).filter(x => x.value !== null); //ensure that the requested filters aren't null (due to switching lt or eq params)
            let filters = (event.filter.filters as FilterDescriptor[]); //ensure that the requested filters aren't null (due to switching lt or eq params)
            if (state.dataState.filter && filters.length) {
                state.dataState.filter.filters = filters;
                this.setState(state, () => { this.filterInputChangeDebounced() });
            }

        } else if (state.dataState.filter && state.dataState.filter.filters.length) { //if no filter being requested, but we have some in state, empty it            
            state.dataState.filter.filters = [];
            this.setState(state, () => { this.filterInputChangeDebounced() });
        }
    }

    private filterInputChangeDebounced = debounce(this.filterInputChange, 500);
    private filterInputChange(): Promise<boolean> {
        this.fetch();
        return Promise.resolve(false);
    }

    public render() {
        return (<>
            <Title string="Investors" />
            <h3 className="mb-2">Investors</h3>
            {this.state.isLoading && <LoadingPanel />}
            <ExcelExport data={this.state.data} ref={this.export}>
                <Grid
                    scrollable="none"
                    pageable={{ pageSizes: [50, 100, 200, 500] }}
                    total={this.state.totalRecords}
                    data={this.state.data}
                    {...this.state.dataState}
                    onDataStateChange={this.dataStateChange}
                    resizable={true}
                    filterable
                    onFilterChange={this.filterChange}
                    onSortChange={this.sortChange}
                    sortable={{
                        allowUnsort: true,
                        mode: 'multiple'
                    }}
                >
                    <GridToolbar>
                        <Link
                            to={paths.InvestorSignup}
                            role="button"
                            className="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-primary k-rounded-md k-icon-button"
                            title="Add Investor"
                        >
                            <span role="presentation" style={{ color: 'white' }} className="k-button-icon k-icon k-i-add"></span>
                        </Link>
                        {this.state.isAdmin && <Button
                            icon="download"
                            svgIcon={downloadIcon}
                            title='Download'
                            style={{ marginLeft: 'auto' }}
                            onClick={this.download}
                        />}
                    </GridToolbar>
                    <Column field="FullName" title="Name" cell={InvestorNameCell(this.state.isAdmin)} />
                    <Column field="InvestorType" title="Type" cell={InvestorTypeCell} filterCell={InvestorTypeFilterCell(GridCellFilterType.ServerSide)} />
                    <Column field="PhoneNumber" title="Phone" cell={PhoneNumberCell} />
                    <Column field="Email" cell={EmailAddressCell} />
                    <Column field="Active" cell={ActiveCell} filterCell={ActiveFilterCell(GridCellFilterType.ServerSide)} />
                    <Column field="MaxInvestmentAmount" title="Max Amount" filter="numeric" format="{0:c}" />
                    <Column field="SignupFeePaid" title="Fee Paid" filter="boolean" />
                    <Column field="CreatedDateTime" title="Created Date" cell={DateTimeCell} filterable={false} />
                </Grid>
            </ExcelExport>
        </>);
    }

    public componentDidMount() {
        this.fetch();

        drawRefreshButtonOnGridFooter(() => { this.fetch() });
    }

    private download() {
        this.export.current?.save();
    }

    private fetch() {
        this.setState({
            isLoading: true
        })

        const dataGridState = this.state.dataState;
        let queryStr = `${toDataSourceRequestString(dataGridState)}`;

        fetchApi(`/api/Investor/List?${queryStr}`)
            .then((response: { DataSourceResult: any, isAdmin: boolean, }) => {
                this.setState({ isLoading: false, data: response.DataSourceResult.Data, totalRecords: response.DataSourceResult.Total, isAdmin: response.isAdmin });
            })
    }
}