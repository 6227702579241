import { GridCellProps } from "@progress/kendo-react-grid";
import { Link } from 'react-router-dom';
import { AgentViewModel } from '../../utils/agent';

export default function AgentNameCell(isAdmin: boolean) {
    return (props: GridCellProps) => {
        const dataItem = props.dataItem as AgentViewModel;
        return (
            <td>
                {isAdmin ?
                    <Link to={`/Agent/${dataItem.AgentID}/Profile`} style={{ color: '#007bff' }}>
                        {dataItem.FullName}
                    </Link> :
                    <span>{dataItem.FullName}</span>}
            </td >
        );
    }
}