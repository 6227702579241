import { GridCellProps } from "@progress/kendo-react-grid";
import { InvestorViewModel, getInvestorType } from '../../utils/investor';

export const InvestorTypeCell = (props: GridCellProps) => {
    const dataItem = props.dataItem as InvestorViewModel;
    return (
        <td>
            {getInvestorType(dataItem.InvestorType)}
        </td>
    );
}