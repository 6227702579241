import { GridCellProps } from "@progress/kendo-react-grid";
import { Link } from 'react-router-dom';
import { InvestorViewModel } from '../../utils/investor';

export default function InvestorNameCell(isAdmin: boolean) {
    return (props: GridCellProps) => {
        const dataItem = props.dataItem as InvestorViewModel;
        return (
            <td>
                {isAdmin ?
                    <Link to={`/Investor/${dataItem.InvestorID}/Profile`} style={{ color: '#007bff' }}>
                        {dataItem.FullName}
                    </Link> :
                    <span>{dataItem.FullName}</span>}
            </td >
        );
    }
}