import { useState } from "react"
import { Button } from '@progress/kendo-react-buttons';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import LoadingPanel from "../LoadingPanel"
import { fetchApi } from "../../services/api";
import { Title } from "../../utils/title";
import { useLoaderData } from "react-router";

type CompanySettingsType = {
    HowYouHeard: string[];
    PropertyTags: string[];
}

export const CompanySettingsLoader = (): Promise<CompanySettingsType> => {
    return fetchApi("/api/User/CompanySettings");
}

const CompanySettings = () => {

    const response = useLoaderData() as CompanySettingsType;

    const [saving, setSaving] = useState<boolean>(false);
    const [howYouHeard, setHowYouHeard] = useState<string[]>(response.HowYouHeard);
    const [propertyTags, setPropertyTags] = useState<string[]>(response.PropertyTags);

    const _save = () => {
        const data = {
            HowYouHeard: howYouHeard,
            PropertyTags: propertyTags
        } as CompanySettingsType;
        setSaving(true);
        fetchApi('/api/User/CompanySettings', data, 'POST')
            .then(() => {
                setSaving(false);
            })
            .catch(() => {
                setSaving(false);
                alert('Error saving settings');
            });
    }

    return <div>
        <Title string="Company Settings" />
        {saving && <LoadingPanel />}
        <div className="k-form k-form-md container">
            <h1>Company Settings</h1>

            <legend>Backend Website</legend>

            <div className="k-form-field">
                <label>Property Labels</label>
                <MultiSelect
                    data={[]}
                    onChange={(e) => {
                        setPropertyTags(e.target.value);
                    }}
                    value={propertyTags}
                    allowCustom={true}
                />
            </div>

            <br />

            <legend>Agent Mobile App</legend>

            <div className="k-form-field">
                <label>How you heard?</label>
                <MultiSelect
                    data={[]}
                    onChange={(e) => {
                        setHowYouHeard(e.target.value);
                    }}
                    value={howYouHeard}
                    allowCustom={true}
                />
            </div>

            <br />

            <Button onClick={_save} themeColor="primary">Save Settings</Button>
        </div>
    </div>
}

export default CompanySettings;