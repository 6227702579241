import moment from 'moment';
import { TaskType } from './Task';
import { Grid, GridCellProps, GridColumn, GridCustomCellProps, GridDataStateChangeEvent, GridExpandChangeEvent } from '@progress/kendo-react-grid';
import { cloneElement, isValidElement, useCallback, useState } from 'react';
import { GroupDescriptor, State as DataState, process, AggregateDescriptor } from '@progress/kendo-data-query';
import { formatNumber } from '@telerik/kendo-intl';
import { setGroupIds } from '@progress/kendo-react-data-tools';

enum Status {
    ON_TRACK = 'On Track',
    PARTIALLY_BEHIND = 'Partially Behind',
    VERY_BEHIND = 'Very Behind',
}

// Weird bug makes group headers span too many columns
const NUM_COLUMNS = 7;

type AugmentedTaskType = TaskType & {
    status: Status;
    drawGoal: number;
    drawActual: number;
    'Week #': number;
};

type Props = {
    data: TaskType[];
};

const aggregates: AggregateDescriptor[] = [
    { field: "drawBudget", aggregate: "sum" },
    { field: "drawGoal", aggregate: "sum" },
    { field: "drawActual", aggregate: "sum" }
  ];

const processWithGroups = (data: unknown[], dataState: DataState) => {
    const groups = dataState.group;
    if (groups) {
        groups.map((group) => (group.aggregates = aggregates));
    }
    dataState.group = groups;
    const newDataState = process(data, dataState);
    setGroupIds({
        data: newDataState.data,
        group: dataState.group,
    });
    return newDataState;
};

const setExpandedState = (data: any, collapsedIds: string[]) => {
    const collapsedIdsSet = new Set(collapsedIds);
    return data.data.map((v: { groupId: string }) => {
        return {
            ...v,
            expanded: !collapsedIdsSet.has(v.groupId),
        };
    });
};

const getTaskStatus = (task: TaskType): Status => {
    if (task.percentComplete >= task.percentGoal) return Status.ON_TRACK;
    if (task.percentGoal - task.percentComplete > 50) return Status.PARTIALLY_BEHIND;
    return Status.VERY_BEHIND;
};

const augmentData = (tasks: TaskType[]): AugmentedTaskType[] =>
    tasks.map((task) => ({
        ...task,
        status: getTaskStatus(task),
        drawGoal: task.drawBudget * task.percentGoal,
        drawActual: task.drawBudget * task.percentComplete,
        'Week #': task.week,
    }));

const initialGroups: GroupDescriptor[] = [{ field: 'Week #' }];

const CustomGroupHeaderCell = (numHiddenCols: number) => (props: GridCustomCellProps) => {
    if (isValidElement(props.children)) {
        const originalPropsChildren = props.children.props.children;
        const originalDisplayValue = originalPropsChildren[1];
        let newProps = props.children.props;
        if (props.dataItem.field === 'Week #') {
            newProps = { ...props.children.props, children: [originalPropsChildren[0], `Week ${originalDisplayValue}`] };
        }
        const clone = cloneElement(props.children, newProps);
        return (
            <td {...props.tdProps} colSpan={NUM_COLUMNS - numHiddenCols}>
                {clone}
            </td>
        );
    }
    return <td {...props.tdProps}>{props.children}</td>;
};
const STATUS_CELL_BACKGROUND_COLORS: Record<Status, string> = {
    'On Track': '#57C37E',
    'Partially Behind': '#F5B96F',
    'Very Behind': '#D24F67',
};
const CustomStatusCell = (props: GridCustomCellProps) => {
    return (
        <td {...props.tdProps} style={{ backgroundColor: STATUS_CELL_BACKGROUND_COLORS[props.dataItem.status as Status], color: 'white' }}>
            {props.children}
        </td>
    );
};

const TableView = ({ data: rawData }: Props) => {
    const data = augmentData(rawData);
    const [dataState, setDataState] = useState<DataState>({
        group: initialGroups,
    });
    const [collapsedState, setCollapsedState] = useState<any[]>([]);

    const onDataStateChange = useCallback((event: GridDataStateChangeEvent) => {
        const { dataState: newDataState } = event;
        const uniqueGroups = new Set();
        const calculatedGroups = newDataState.group?.filter((g) => {
            if (uniqueGroups.has(g.field)) {
                return false;
            } else {
                uniqueGroups.add(g.field);
                return true;
            }
        });
        const calculatedDataState = { ...newDataState, group: calculatedGroups };
        setDataState(calculatedDataState);
    }, []);

    const onExpandChange = useCallback((event: GridExpandChangeEvent) => {
        const item = event.dataItem;
        if (item.groupId) {
            setCollapsedState((oldCollapsedState) => (!event.value ? [...oldCollapsedState, item.groupId] : oldCollapsedState.filter((groupId) => groupId !== item.groupId)));
        }
    }, []);

    const cellRender = (
        tdElement: React.ReactElement<HTMLTableCellElement> | null,
        cellProps: GridCellProps
      ) => {
        if (cellProps.rowType === "groupFooter") {
          if (cellProps.field === "drawBudget") {
            return (
              <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem.aggregates.drawBudget.sum, "c0")}
              </td>
            );
          } else if (cellProps.field === "drawGoal") {
            return (
              <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem.aggregates.drawGoal.sum, "c0")}
              </td>
            );
          } else if (cellProps.field === "drawActual") {
            return (
              <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem.aggregates.drawActual.sum, "c0")}
              </td>
            );
          }
        }
        return tdElement;
      };

    const processedData = setExpandedState(processWithGroups(data, dataState), collapsedState);
    const canHideGroupCol = dataState.group?.some((group) => group.field === 'Week #');

    return (
        <Grid
            data={processedData}
            groupable={{ footer: "visible" }}
            sortable
            {...dataState}
            onDataStateChange={onDataStateChange}
            onExpandChange={onExpandChange}
            expandField="expanded"
            cells={{
                group: {
                    groupHeader: CustomGroupHeaderCell(canHideGroupCol ? 1 : 0),
                },
            }}
            cellRender={cellRender}
        >
            {canHideGroupCol ? null : <GridColumn field="Week #" title="Week #" filter="numeric" />}
            <GridColumn field="title" title="Title" />
            <GridColumn
                field="end"
                title="Completed by"
                cells={{
                    data: (props) => {
                        let val = '';
                        if (props.field) {
                            val = props.dataItem[props.field];
                        }
                        return <td>{moment(val).format('MMM DD')}</td>;
                    },
                }}
            />
            <GridColumn field="percentGoal" title="Goal %" format='{0:p}' />
            <GridColumn field="percentComplete" title="Actual %" format='{0:p}' />
            <GridColumn
                field="status"
                title="Goal Status"
                cells={{
                    data: CustomStatusCell,
                }}
            />
            <GridColumn field="drawBudget" title="Draw Budget" filter="numeric" format='{0:c}' />
            <GridColumn field="drawGoal" title="Draw Potential" format='{0:c}' />
            <GridColumn field="drawActual" title="Draw Actual" format='{0:c}' />
        </Grid>
    );
};

export default TableView;
