import * as React from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Button } from '@progress/kendo-react-buttons';
import { fetchApi } from '../../services/api';
import { UserViewModel } from '../../utils/user';
import LoadingPanel from '../LoadingPanel';
import AgentAvatarListing from '../Agent/AgentAvatarListing';
import { AgentViewModel } from '../../utils/agent';
import AgentAvatar from '../Agent/AgentAvatar';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Title } from '../../utils/title';

type Params = {
    UserId: string
}

const UserProfile = () => {
    const { UserId } = useParams<Params>();
    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);
    const [dirty, setDirty] = React.useState(false);
    const [admin, setAdmin] = React.useState(false);
    const [validation, setValidation] = React.useState(false);
    const [user, setUser] = React.useState<UserViewModel | null>(null);
    const [associatedAgent, setAssociatedAgent] = React.useState<AgentViewModel | null>(null);
    const [newRecord, setNewRecord] = React.useState(false);
    const [agentAvatarListingAnchor, setAgentAvatarListingAnchor] = React.useState<HTMLLabelElement>();

    const updateUserName = (e: any) => {
        if (user && newRecord) {
            if (user.FirstName && user.LastName && !user.UserName) {
                setUser({
                    ...user,
                    UserName:
                        (user.FirstName.charAt(0).toUpperCase() +
                            user.LastName.charAt(0).toUpperCase() +
                            (user.LastName.length > 1 ? user.LastName.substr(1, 11) : "")).replace(/[^a-zA-Z0-9]/g, "")
                });
            }
        }
    }

    const avatarAnchorCallBack = (e: HTMLLabelElement) => {
        setAgentAvatarListingAnchor(e);
    }

    const updateUserState = (value: Partial<UserViewModel>) => {
        const newUser = Object.assign({}, user, value);
        setUser(newUser);
        setDirty(true);
    }

    const requestUser = (userId: string | undefined) => {
        setLoading(true);
        fetchApi(`/api/User/Get/${userId}`)
            .then((response) => {
                setUser(response.user);
                setAdmin(response.isAdmin);
                setAssociatedAgent(response.associatedAgent);
                setLoading(false);
                setDirty(false);
            });
    }

    const emptyRecord = () => {
        const newUser = {
            Id: 'new',
            FirstName: '',
            LastName: '',
            UserName: '',
            PhoneNumber: '',
            Email: '',
            IsAdmin: false,
            IsLockedOut: false,
            ReceiveSubmittedEmails: false,
            ReceiveInitiatedEmails: false,
            ReceiveBidEmails: false,
            ReceiveOfferEmails: false,
            ReceiveNoteEmails: false
        } as UserViewModel;
        setUser(newUser);
    }

    const save = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        setValidation(true);
        if (user) {
            if (user.FirstName && user.LastName && user.UserName && user.Email) {
                setLoading(true);
                if (newRecord) {
                    fetchApi(`/api/User/Create`, user, 'POST')
                        .then((response: any) => {
                            setLoading(false);
                            setDirty(false);

                            setUser({ ...user, Id: response.Id });
                            navigate("/User/Index");
                        }).catch((message: Error) => {
                            setLoading(false);
                            alert(message);
                        });
                } else {
                    fetchApi(`/api/User/Edit`, user, 'POST')
                        .then(() => {
                            setLoading(false);
                            setDirty(false);

                            navigate("/User/Index");
                        }).catch((message: Error) => {
                            setLoading(false);
                            alert(message);
                        });
                }
            }
        }
    }

    const resetPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (user) {
            setLoading(true);
            fetchApi(`/api/User/SendResetPassword/${user.Id}`, {}, 'POST')
                .then(() => {
                    setLoading(false);
                    setDirty(false);

                    alert("Password Reset Email Sent");
                    navigate("/User/Index");
                }).catch((message: Error) => {
                    setLoading(false);
                    alert(message);
                });
        }
    }

    const retireUser = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (user) {
            setLoading(true);
            fetchApi(`/api/User/Retire/${user.Id}`, {}, 'GET')
                .then(() => {
                    setLoading(false);
                    setDirty(false);
                    setUser({ ...user, IsLockedOut: true });

                    alert("User is now retired");
                    navigate("/User/Index");
                }).catch((message: Error) => {
                    setLoading(false);
                    alert(message);
                });
        }
    }

    const unRetireUser = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (user) {
            setLoading(true);
            fetchApi(`/api/User/UnRetire/${user.Id}`, {}, 'POST')
                .then(() => {
                    setLoading(false);
                    setDirty(false);
                    setUser({ ...user, IsLockedOut: false });

                    alert("User is now active");
                    navigate("/User/Index");
                }).catch((message: Error) => {
                    setLoading(false);
                    alert(message);
                });
        }
    }

    React.useEffect(() => {
        setNewRecord(UserId === 'new');
        if (UserId === 'new') {
            emptyRecord();
        } else {
            requestUser(UserId);
        }
    }, [UserId])

    return <>
        {loading && <LoadingPanel />}
        <Title string={user?.FirstName || 'New User'} />
        {user &&
            <div className="container">
                <h1>User Profile</h1>

                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group mb-2">
                            <label>First Name {validation && !user.FirstName && <span className="error-msg">*Required</span>}</label>
                            <input
                                className="form-control"
                                type="text"
                                name="UserFirstName"
                                placeholder="First Name"
                                value={user.FirstName}
                                onChange={(e) => updateUserState({ FirstName: e.target.value })}
                                onBlur={updateUserName}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group mb-2">
                            <label className="">Last Name {validation && !user.LastName && <span className="error-msg">*Required</span>}</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Last Name"
                                value={user.LastName}
                                onChange={(e) => updateUserState({ LastName: e.target.value })}
                                onBlur={updateUserName}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group mb-2">
                            <label>User Name {validation && !user.UserName && <span className="error-msg">*Required</span>}</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="User Name"
                                value={user.UserName}
                                onChange={(e) => updateUserState({ UserName: e.target.value.replace(/[^a-zA-Z0-9]/g, "") })}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group mb-2">
                            <label>E-Mail {validation && !user.Email && <span className="error-msg">*Required</span>}</label>
                            <input
                                className="form-control"
                                type="email"
                                placeholder="Email"
                                value={user.Email}
                                onChange={(e) => updateUserState({ Email: e.target.value })}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group mb-2">
                            <label ref={avatarAnchorCallBack}>Associated Agent</label>
                            {associatedAgent ?
                                <AgentAvatar
                                    onIconClicked={() => {
                                        setUser({ ...user, AssociatedAgentID: null });
                                        setAssociatedAgent(null);
                                        setDirty(true);
                                    }}
                                    agent={associatedAgent}
                                /> :
                                <div style={{ position: "relative" }}>
                                    <AgentAvatarListing
                                        anchor={agentAvatarListingAnchor as HTMLElement}
                                        close={(agent: AgentViewModel | undefined) => {
                                            if (agent) {
                                                setUser({ ...user, AssociatedAgentID: agent.AgentID });
                                                setAssociatedAgent(agent);
                                                setDirty(true);
                                            }
                                        }}
                                        showSearchInput={true}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="k-form-field mb-1">
                            <Checkbox
                                label="Administrator"
                                checked={user.IsAdmin}
                                disabled={!newRecord && !admin}
                                onChange={(e) => updateUserState({ IsAdmin: e.value })}
                            />
                        </div>

                        <div className="k-form-field mb-1">
                            <Checkbox
                                label="Inside Agent"
                                checked={user.IsInsideAgent}
                                disabled={!newRecord && !admin}
                                onChange={(e) => updateUserState({ IsInsideAgent: e.value })}
                            />
                        </div>

                        <div className="k-form-field mb-1">
                            <Checkbox
                                label="Receive Scout Partner Submission Emails"
                                checked={user.ReceiveSubmittedEmails}
                                onChange={(e) => updateUserState({ ReceiveSubmittedEmails: e.value })}
                            />
                        </div>

                        <div className="k-form-field mb-1">
                            <Checkbox
                                label="Receive Scout Premium Initiated Emails"
                                checked={user.ReceiveInitiatedEmails}
                                onChange={(e) => updateUserState({ ReceiveInitiatedEmails: e.value })}
                            />
                        </div>

                        <div className="k-form-field mb-1">
                            <Checkbox
                                label="Receive Bid Emails"
                                checked={user.ReceiveBidEmails}
                                onChange={(e) => updateUserState({ ReceiveBidEmails: e.value })}
                            />
                        </div>

                        <div className="k-form-field mb-1">
                            <Checkbox
                                label="Receive Offer Emails"
                                checked={user.ReceiveOfferEmails}
                                onChange={(e) => updateUserState({ ReceiveOfferEmails: e.value })}
                            />
                        </div>

                        <div className="k-form-field mb-1">
                            <Checkbox
                                label="Receive Property Note Emails"
                                checked={user.ReceiveNoteEmails}
                                onChange={(e) => updateUserState({ ReceiveNoteEmails: e.value })}
                            />
                        </div>
                    </div>
                </div>

                <div className="text-end mt-4">
                    {user.IsLockedOut && admin &&
                        <Button className="ms-2" onClick={(e) => window.confirm("Un-retire user?") && unRetireUser(e)}>Un-Retire User</Button>}
                    {!newRecord && !user.IsLockedOut && admin &&
                        <Button className="ms-2" onClick={(e) => window.confirm("Retire user?") && retireUser(e)}>Retire User</Button>}
                    {(!newRecord && !user.IsLockedOut) &&
                        user.IsSelf ?
                        <Link to={`/User/ChangePassword`} className="ms-2" style={{ textDecoration: 'none' }}>
                            <Button>Change Password</Button>
                        </Link> :
                        <Button className="ms-2" onClick={(e) => window.confirm('Send password reset email?') && resetPassword(e)}>Reset Password</Button>
                    }
                    <Button themeColor="primary" className="ms-2" disabled={!dirty} onClick={save}>{newRecord ? 'Create' : 'Update'}</Button>
                </div>
            </div>
        }
    </>;
}

export default UserProfile;
