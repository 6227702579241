import { GridCellProps } from "@progress/kendo-react-grid";
import { formatPhoneNumber } from '../utils/generic';

export const PhoneNumberCell = (props: GridCellProps) => {
    if (!props.field) return null;
    return (
        <td>
            {formatPhoneNumber(props.dataItem[props.field])}
        </td>
    );
}