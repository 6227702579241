import * as React from 'react';
import { GridFilterCell, GridFilterCellProps } from '@progress/kendo-react-grid';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { GridCellFilterType } from '../../utils/kendo';
import { IdTextPair } from '../../utils/generic';
import { InvestorType, getInvestorType } from '../../utils/investor';
import { Button } from '@progress/kendo-react-buttons';

export default function InvestorTypeFilterCell(filterType: GridCellFilterType) {
    return class extends GridFilterCell {
        private statusOption: IdTextPair[] = [];

        constructor(props: GridFilterCellProps) {
            super(props);
            for (var enumMember in InvestorType) {
                const num = Number(enumMember);
                if (!isNaN(num)) {
                    this.statusOption.push({ text: getInvestorType(num), id: num });
                }
            }
        };

        onClearButtonClick = (event: any) => {
            event.preventDefault();
            this.props.onChange({
                value: '',
                operator: '',
                syntheticEvent: event
            });
        }

        getIdTextPair = () => {
            switch (filterType) {
                case GridCellFilterType.ServerSide:
                    return this.statusOption.filter(x => x.id === this.props.value)[0];
                default:
                    return this.statusOption.filter(x => x.text === this.props.value)[0];
            }
        };

        getPairValue = (idTextPair: IdTextPair) => {
            switch (filterType) {
                case GridCellFilterType.ServerSide:
                    return idTextPair.id;
                default:
                    return idTextPair.text;
            }
        };

        onChange = (event: any) => {
            const value = this.getPairValue(event.target.value as IdTextPair);
            this.props.onChange({
                value: value,
                operator: 'eq',
                syntheticEvent: event.syntheticEvent
            });
        }

        render() {
            const value = this.getIdTextPair();
            return (
                <div className='k-filtercell'>
                    <DropDownList
                        data={this.statusOption}
                        textField="text"
                        dataItemKey="id"
                        style={{ minWidth: "75px" }}
                        onChange={this.onChange}
                        value={value}
                    />
                    &nbsp;
                    <Button
                        icon="filter-clear"
                        className="k-clear-button-visible"
                        title="Clear"
                        disabled={!value}
                        onClick={this.onClearButtonClick}
                    />
                </div>
            );
        }
    }
}