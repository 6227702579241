import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthorizationContext } from '../../store/authentication';
import { Container } from 'reactstrap';
import { useContext } from 'react';
import { Title } from '../../utils/title';

const Login = () => {

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const navigate = useNavigate();
    const { state, getUserToken } = useContext(AuthorizationContext);

    React.useEffect(() => {
        if (state.token) {
            if (state.redirectUrl) {
                navigate(state.redirectUrl);
            } else {
                navigate("/Property/Index");
            }
        }
    }, [navigate, state.token, state.redirectUrl]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        if (!email || !password) {
            return;
        }

        getUserToken(email, password);
    }

    return (
        <Container>
            <Title string="Login" />
            <h1>Login</h1>
            <form className="form-signin" onSubmit={handleSubmit}>
                <div className="form-label-group" style={{ paddingBottom: 10 }}>
                    <input
                        type="text"
                        id="inputEmail"
                        className="form-control"
                        placeholder="Email"
                        autoComplete="email"
                        required
                        onChange={(event) => setEmail(event.target.value)}
                    />
                </div>
                <div className="form-label-group" style={{ paddingBottom: 10 }}>
                    <input
                        type="password"
                        id="inputPassword"
                        className="form-control"
                        placeholder="Password"
                        required
                        autoComplete="current-password"
                        onChange={(event) => setPassword(event.target.value)}
                    />
                </div>
                <button type="submit" className="btn btn-lg btn-primary btn-block" onClick={handleSubmit}>Sign in</button>
                <p className="mt-5 mb-3 text-muted text-center">&copy; {(new Date()).getFullYear()} Belwood Investments</p>
            </form>
        </Container>
    );
}

export default Login;
