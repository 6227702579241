import * as React from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { AuthorizationContext } from '../store/authentication';
import { Avatar } from '@progress/kendo-react-layout';
import { fetchApi } from '../services/api';
import { paths } from '../App';
import { useContext, useEffect } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { version } from '../version';

export interface ILink {
    Rel: string;
    Href: string;
    Method: string;
};

const NavMenu = () => {

    const navigate = useNavigate();
    const { state } = useContext(AuthorizationContext);
    const { switcher, currentTheme, themes } = useThemeSwitcher();
    const isDarkMode = currentTheme === 'dark';

    const [isOpen, setIsOpen] = React.useState(false);
    const [isAvatarOpen, setIsAvatarOpen] = React.useState(false);
    const [links, setLinks] = React.useState<ILink[]>([]);

    const fetch = () => {
        fetchApi("/api/User/Info")
            .then((response: { Id: string, Username: string, Links: ILink[] }) => {
                setLinks(response.Links);
            });
    }

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    const toggleAvatarMenu = () => {
        setIsAvatarOpen(!isAvatarOpen);
    }

    useEffect(() => {
        if (state.token) {
            fetch();
        }
    }, [navigate, state.token]);

    useEffect(() => {
        if (currentTheme === 'dark') {
            localStorage.setItem("darkmode", "true");
        } else {
            localStorage.removeItem("darkmode")
        }
    }, [currentTheme]);

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                <NavbarBrand tag={Link} className={`${isDarkMode ? 'text-light' : 'text-dark'}`} to="/">Belwood Investments</NavbarBrand>
                <NavbarToggler onClick={toggle} className={`me-2 ${isDarkMode ? 'navbar-dark' : 'navbar-light'}`} />
                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={isOpen} navbar>
                    <ul className="navbar-nav flex-grow">
                        {state.token ? <>
                            {links.find(x => x.Rel === "Properties") && <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret className={`${isDarkMode ? 'text-light' : 'text-dark'}`}>
                                    Properties
                                </DropdownToggle>
                                <DropdownMenu end dark={isDarkMode}>
                                    <DropdownItem tag={Link} to={paths.Property}>Listing</DropdownItem>
                                    <DropdownItem tag={Link} to={paths.PropertyMap}>Map</DropdownItem>
                                    <DropdownItem tag={Link} to={paths.PropertyCalculator}>Calculator</DropdownItem>
                                    <DropdownItem tag={Link} to={paths.PropertyRegions}>Locations</DropdownItem>
                                    <DropdownItem tag={Link} to={paths.PropertyRegionCost}>Region Cost</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>}
                            {links.find(x => x.Rel === "Construction") && <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret className={`${isDarkMode ? 'text-light' : 'text-dark'}`}>
                                    Construction
                                </DropdownToggle>
                                <DropdownMenu end dark={isDarkMode}>
                                    <DropdownItem tag={Link} to={paths.Projects}>Projects</DropdownItem>
                                    <DropdownItem tag={Link} to={paths.Contractor}>Contractors</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>}
                            {links.find(x => x.Rel === "Agents") && <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret className={`${isDarkMode ? 'text-light' : 'text-dark'}`}>
                                    Agents
                                </DropdownToggle>
                                <DropdownMenu end dark={isDarkMode}>
                                    <DropdownItem tag={Link} to={paths.Agent}>Listing</DropdownItem>
                                    <DropdownItem tag={Link} to={paths.AgentNotifications}>Notifications</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>}
                            {links.find(x => x.Rel === "Investors") && <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret className={`${isDarkMode ? 'text-light' : 'text-dark'}`}>
                                    Investors
                                </DropdownToggle>
                                <DropdownMenu end dark={isDarkMode}>
                                    <DropdownItem tag={Link} to={paths.Investor}>Listing</DropdownItem>
                                    <DropdownItem tag={Link} to={paths.InvestorNotifications}>Notifications</DropdownItem>
                                    <DropdownItem tag={Link} to={paths.InvestorRewardsClaimed}>Rewards Claimed</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>}
                            {links.find(x => x.Rel === "EscrowCompanies") && <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret className={`${isDarkMode ? 'text-light' : 'text-dark'}`}>
                                    Escrow Companies
                                </DropdownToggle>
                                <DropdownMenu end dark={isDarkMode}>
                                    <DropdownItem tag={Link} to={paths.EscrowCompany}>Listing</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>}
                            {links.find(x => x.Rel === "LeadGeneration") && <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret className={`${isDarkMode ? 'text-light' : 'text-dark'}`}>
                                    Lead Generation
                                </DropdownToggle>
                                <DropdownMenu end dark={isDarkMode}>
                                    <DropdownItem tag={Link} to={paths.UploadCallLeads}>Upload Call Leads</DropdownItem>
                                    <DropdownItem tag={Link} to={paths.UploadSMSLeads}>Upload SMS Leads</DropdownItem>
                                    <DropdownItem tag={Link} to={paths.PredictFixer}>Predict Fixer Backtest</DropdownItem>
                                    <DropdownItem tag={Link} to={paths.AppReferrals}>App Referrals</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>}
                            {links.find(x => x.Rel === "Users") && <NavItem>
                                <NavLink tag={Link} className={`${isDarkMode ? 'text-light' : 'text-dark'}`} to={paths.Users}>Users</NavLink>
                            </NavItem>}
                            {links.find(x => x.Rel === "PayFees") && <NavItem>
                                <NavLink tag={Link} className={`${isDarkMode ? 'text-light' : 'text-dark'}`} to={paths.Payment}>Pay Fees</NavLink>
                            </NavItem>}
                            <Dropdown isOpen={isAvatarOpen} toggle={toggleAvatarMenu} className={`${isDarkMode ? 'text-light' : 'text-dark'} nav-link`}
                                style={{ cursor: "pointer", marginTop: "-3px" }}>
                                <DropdownToggle tag="span" data-toggle="dropdown">
                                    <Avatar type='icon'><span className='k-icon k-i-user' /></Avatar>
                                </DropdownToggle>
                                <DropdownMenu style={{ left: "-128px" }} dark={isDarkMode}>
                                    <DropdownItem tag={Link} to={`/user/${localStorage.getItem('USER_ID')}/userprofile`} >
                                        User Profile
                                    </DropdownItem>
                                    {links.find(x => x.Rel === "CompanySettings") && <>
                                        <DropdownItem divider />
                                        <DropdownItem tag={Link} to={paths.CompanySettings}>
                                            Company Settings
                                        </DropdownItem>
                                    </>}
                                    <DropdownItem divider />
                                    <DropdownItem tag={Link} to={paths.ChangePassword}>
                                        Change Password
                                    </DropdownItem>
                                    <DropdownItem tag={Link} to={paths.UserLogout}>
                                        Logout
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem tag="a" onClick={() => switcher({ theme: isDarkMode ? themes.light : themes.dark })}>
                                        {isDarkMode ? 'Light Mode' : 'Dark Mode'}
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem disabled>
                                        {version}
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </>
                            : <NavLink tag={Link} className={`${isDarkMode ? 'text-light' : 'text-dark'}`} to={paths.UserLogin}>Login</NavLink>}
                    </ul>
                </Collapse>
            </Navbar>
        </header>
    );
}

export default NavMenu;