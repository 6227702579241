import * as Moment from 'moment';
import { PhysicalAttribute, PhysicalCondition } from '../components/Property/PropertyProfileAttributes';

export const stateValidator = (state: string) => {
    if (!state || state.length <= 0) return 'State cannot be empty.';
    if (state.length !== 2) return 'Ooops! We need a valid state code.';
    return '';
}

export const zipValidator = (zip: string) => {
    if (!zip || zip.length <= 0) return 'Zip cannot be empty.';
    if (zip.length < 5) return 'Ooops! We need a valid zip code.';
    return '';
}

export const yearBuiltValidator = (yearBuilt: number) => {
    if (yearBuilt.toString().length !== 4) return 'Ooops! We need a valid Year Built.';
    return '';
}

export const hudHomeValidator = (hudHome: boolean | null) => {
    if (hudHome === null) return 'Select HUD Home.';
    if (hudHome === true) return 'Sorry, we can’t accept this property!';
    return '';
 }

 export const auctionHomeValidator = (auctionHome: boolean | null) => {
    if (auctionHome === null) return 'Select Auction Home.';
    if (auctionHome === true) return 'Sorry, we can’t accept this property!';
    return '';
 }

 export const tenantsValidator = (tenants: number | null) => {
    if (tenants === null) return 'Select Tenants.';
    if (tenants === 1) return 'Sorry, we can’t accept this property!';
    return '';
}

export const physicalAttributeValidator = (attribute: PhysicalAttribute) => {
    if (!attribute.back && !attribute.front && !attribute.none) return 'Select an option.';
    if (attribute.none && (attribute.front || attribute.back)) return 'Invalid selection.';
    if (!attribute.none && attribute.images.length === 0) return 'Photo required.';
    return '';
}

export const physicalConditionValidator = (attribute: PhysicalCondition) => {
    if (attribute.good == null) return 'Select an option.';
    if (attribute.images.length === 0) return 'Photo required.';
    return '';
}

export const isPositiveValidator = (value: number, error: string) => {
    if (value <= 0) return error;
    return '';
}

export const isNullOrEmptyValidator = (value: string, error: string) => {
    if (!value || value.length <= 0) return error;
    return '';
}

export const isNullValidator = (value: boolean | number | string | null, error: string) => {
    if (value == null) return error;
    return '';
}

export const isValidUrl = (value: string, error: string): string => {
    let url;

    try {
      url = new URL(value);
    } catch (_) {
      return error;
    }

    return url.protocol === "https:" ? '' : error;
}

export const EMAIL_REGEX = /\S+@\S+\.\S+/;

export const emailValidator = (email: string) => {
    if (!email || email.length <= 0) return 'Email cannot be empty.';
    if (!EMAIL_REGEX.test(email)) return 'Ooops! We need a valid email address.';
  
    return '';
  }
  
  export const phoneValidator = (phone: string) => {
    if (!phone || phone.length <= 0) return 'Phone cannot be empty.';
    if (phone.length <= 9) return 'Ooops! We need a valid phone number.';
  
    return '';
  }

export function timeSpanToHHmmFormat(timeSpan: string): string {
    var dur = Moment.duration(timeSpan);
    var daysToHrs = dur.days() * 24;
    var totHrs = (dur.hours() + daysToHrs);

    return `${prefixLeadingZero(totHrs)}:${prefixLeadingZero(dur.minutes())}`;
}

export function prefixLeadingZero(n: number): string {
    return n < 10 ? '0' + n : '' + n;
}

export function formatPhoneNumber(phoneNumberString: string) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
}

export function formatCurrency(value: number) {
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
    });

    return formatter.format(value); /* 2500 becomes $2,500.00 */
}

export function formatNumber(value: number) {
    var formatter = new Intl.NumberFormat('en-US', {
        currency: 'USD',
        minimumFractionDigits: 0,
    });

    return formatter.format(value); /* 2500 becomes 2,500.00 */
}

export function generateHash(): string {
    return Math.random().toString(36).substring(7);
}

export type IdTextPair = {
    id: number;
    text: string;
}

export type IDName = {
    ID: number;
    Name: string;
}

export function toYesNo(value: boolean | null) {
    return value === true ? "Yes" : value === false ? "No" : 'N/A';
}

export type JsonResponse = {
    Success: boolean;
    ErrorMessage: string;
    Links: Array<{ Rel: string, Method: string, Href: string }>;
}

export function isExpired(date: Date | null) {
    if (date == null) return false;
    return Moment.utc(date).isBefore(Moment.utc());
}