import * as React from 'react';
import LoadingPanel from '../LoadingPanel';
import { GridPageChangeEvent, Grid, GridColumn as Column, GridSortChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { SortDescriptor, CompositeFilterDescriptor, orderBy, filterBy } from '@progress/kendo-data-query';
import { fetchApi } from '../../services/api';
import { drawRefreshButtonOnGridFooter, GridCellFilterType } from '../../utils/kendo';
import { UserViewModel } from '../../utils/user';
import { PhoneNumberCell } from '../PhoneNumberCell';
import { EmailAddressCell } from '../EmailAddressCell';
import { LockedOutCell } from './LockedOutCell';
import UserStatusFilterCell from './UserStatusFilterCell';
import { Link } from 'react-router-dom';
import UserNameCell from './UserNameCell';
import { Title } from '../../utils/title';

type Props = {}
type State = {
    isLoading: boolean;
    data: UserViewModel[];
    skip: number;
    take: number;
    sort: SortDescriptor[];
    allowUnsort: boolean;
    multiple: boolean;
    filter: CompositeFilterDescriptor;
    showImageViewer: boolean;
    selectedProperty: UserViewModel | null;
    isAdmin: boolean;
}

export default class Users extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            data: [],
            skip: 0,
            take: 50,
            sort: [],
            allowUnsort: true,
            multiple: true,
            filter: {
                logic: "and",
                filters: [{ field: "IsLockedOut", operator: "eq", value: false }]
            } as CompositeFilterDescriptor,
            showImageViewer: false,
            selectedProperty: null,
            isAdmin: false
        }
    }

    pageChange = (event: GridPageChangeEvent) => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take
        });
    }
    sortChange = (event: GridSortChangeEvent) => {
        this.setState({
            data: orderBy(this.state.data, event.sort),
            sort: event.sort
        });
    };

    public render() {

        let data = filterBy(this.state.data, this.state.filter);
        const totalRecords = data.length;
        data = data.slice(this.state.skip, this.state.take + this.state.skip);


        return (<>
            <Title string="Users" />
            <h3 className="mb-2">Users</h3>
            {this.state.isLoading && <LoadingPanel />}
            <Grid
                scrollable="none"
                data={data}
                pageable={true}
                onPageChange={this.pageChange}
                skip={this.state.skip}
                take={this.state.take}
                total={totalRecords}
                sortable={{
                    allowUnsort: this.state.allowUnsort,
                    mode: this.state.multiple ? 'multiple' : 'single'
                }}
                sort={this.state.sort}
                onSortChange={this.sortChange}
                filterable={true}
                filter={this.state.filter}
                onFilterChange={(e) => {
                    this.setState({
                        skip: 0,
                        take: 50,
                        filter: e.filter
                    });
                }}
            >
                <GridToolbar>
                    <div className="text-end">
                        <Link
                            hidden={!this.state.isAdmin}
                            role="button"
                            className="btn btn-primary"
                            style={{ color: "white" }}
                            to={'/user/new/userprofile'}>Add User</Link>
                    </div>
                </GridToolbar>
                <Column field="UserName" title="User Name" cell={UserNameCell(this.state.isAdmin)} />
                <Column field="FirstName" title="First Name" />
                <Column field="LastName" title="Last Name" />
                <Column field="PhoneNumber" title="Phone" cell={PhoneNumberCell} />
                <Column field="Email" title="Email" cell={EmailAddressCell} />
                <Column field="IsLockedOut" title="Status" cell={LockedOutCell} filterCell={UserStatusFilterCell(GridCellFilterType.ServerSide)} />
            </Grid>
        </>);
    }

    public componentDidMount() {
        this.fetch();

        drawRefreshButtonOnGridFooter(() => { this.fetch() });
    }

    private fetch() {
        this.setState({
            isLoading: true
        });

        fetchApi('/api/User/List', {}, 'POST')
            .then((response: any) => {
                this.setState({ isLoading: false, data: response.accounts, isAdmin: response.isAdmin });
            })
    }
}