import * as React from 'react';
import LoadingPanel from './LoadingPanel';
import { ExternalDropZone, Upload, UploadOnStatusChangeEvent } from '@progress/kendo-react-upload';
import { formatNumber } from '@telerik/kendo-intl';
import { Title } from '../utils/title';

const PredictFixer = () => {

    const uploadRef = React.createRef<Upload>();
    const [loading, setLoading] = React.useState(false);
    const [prediction, setPrediction] = React.useState<{
        Label: string,
        Score: number[],
    } | null>(null);

    const onStatusChange = (e: UploadOnStatusChangeEvent) => {
        if (e.response) {
            setLoading(false);
            if (e.response.response.status === 400) {
                setPrediction({
                    Label: 'error',
                    Score: [],
                });
            } else {
                setPrediction(e.response.response);
            }
        }
    }

    return <div className="row align-items-center">
        <div className="col">
            <Title string="Predic Fixer Backtest" />
            {loading && <LoadingPanel />}
            {(prediction && !loading) && <div className='card mb-2'>
                {prediction.Label === 'error'
                    ? <div className={`card-body text-white bg-info`}>
                        <h5 className="card-title">We're not sure!</h5>
                        <p className="card-text">
                            Try a different interior image
                        </p>
                    </div>
                    : <div className={`card-body text-white ${prediction.Label === "bad" ? 'bg-danger' : 'bg-success'}`}>
                        <h5 className="card-title">{prediction.Label === "bad" ? "It's a fixer!" : "Looks good!"}</h5>
                        <p className="card-text">
                            We're {formatNumber( Math.max.apply(0, prediction.Score), 'p')} sure.
                        </p>
                    </div>}
            </div>}
            <ExternalDropZone customHint="Predict Fixer Backtest" customNote="Upload any .jpg image file from a MLS listing." uploadRef={uploadRef} />
            <Upload
                ref={uploadRef}
                batch={false}
                multiple={false}
                defaultFiles={[]}
                withCredentials={false}
                saveField="file"
                saveHeaders={{
                    'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                }}
                restrictions={{
                    allowedExtensions: ['.jpg']
                }}
                accept="image/jpeg"
                saveUrl={`/api/ML/PredictFixer`}
                saveMethod="POST"
                onBeforeUpload={() => setLoading(true)}
                onStatusChange={onStatusChange}
            />
        </div>
    </div>
};


export default PredictFixer;
