import { useCallback, useEffect, useRef, useState } from 'react';
import { PropertyViewModel } from '../../utils/property';
import { CardActions, CardSubtitle, Step, Stepper, StepProps } from "@progress/kendo-react-layout";
import { fetchApi } from '../../services/api';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input, TextArea } from '@progress/kendo-react-inputs';
import Moment from 'moment';
import {
    Card,
    CardHeader,
    CardBody,
} from '@progress/kendo-react-layout';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import { Upload, UploadFileInfo, UploadOnBeforeUploadEvent, UploadOnStatusChangeEvent } from '@progress/kendo-react-upload';
import { ScrollView } from "@progress/kendo-react-scrollview";
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from '@progress/kendo-react-dateinputs';

type Props = {
    property: PropertyViewModel;
}

type ConstructionStatusHistory = {
    Status: number,
    StatusDateTime: Date
}

type ConstructionPostViewModel = {
    ID: number,
    Type: number,
    Url: string,
    Body: string,
    PostedDateTime: Date,
    Children: ConstructionPostViewModel[]
}

type ConstructionInvestmentViewModel = {
    Status: number,
    StatusDateTime?: Date,
    StatusName: string,
    NextStatusName: string,
    Progress: number,
    Posts: ConstructionPostViewModel[],
    ConstructionStatusHistory: ConstructionStatusHistory[]
}

const PropertyConstruction = (props: Props) => {
    const uploadRef = useRef<Upload>(null);
    const [currentStepperValue, setCurrentStepperValue] = useState<number>(0);
    const [stepperValue, setStepperValue] = useState<number>(0);
    const [showNewMessageWindow, SetShowNewMessageWindow] = useState<boolean>(false);
    const [message, setMessage] = useState({ value: '', error: '' });
    const [link, setLink] = useState({ value: '', error: '' });
    const [pictureCaption, setPictureCaption] = useState({ value: '', error: '' });
    const [constructionInvestmentViewModel, setConstructionInvestmentViewModel] = useState<ConstructionInvestmentViewModel>();
    const [statusUpdateDropDownValue, setStatusUpdateDropDownValue] = useState('Select an option...');
    const [constructionStartedOn, setConstructionStartedOn] = useState<Date | null>(new Date());
    //const [confirmDialog, setConfirmDialog] = useState<{ Show: boolean, Title: string, Message: string, Callback: Function | null, ElementBody: JSX.Element }>({ Show: false, Title: ``, Message: ``, Callback: null, ElementBody: <></> });
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [forceUpload, setForceUpload] = useState(false);
    const [files, setFiles] = useState<Array<UploadFileInfo>>([]);

    const items = [
        { index: 0, value: 1, label: "Plans and Permitting" },
        { index: 1, value: 2, label: "Demolition and Establishing Crew" },
        { index: 2, value: 3, label: "Construction" },
        { index: 3, value: 7, label: "Staging, Cleaning, Photos" },
        { index: 4, value: 8, label: "Listed for sale" },
        { index: 5, value: 9, label: "In contract to sell" },
        { index: 6, value: 10, label: "Home is SOLD" },
        { index: 7, value: 11, label: "Being refinanced to rent" },
        { index: 8, value: 12, label: "Home is rented" },
    ] as StepProps[];

    const _fetchConstructionInvestment = useCallback(() => {
        fetchApi(`/api/Property/Investment/${props.property.PropertyID}`)
            .then((data: ConstructionInvestmentViewModel) => {
                setConstructionInvestmentViewModel(data);
                setStepperValue(data.Status / 10);
                setCurrentStepperValue(data.Status / 10);
                //setLoading(false);
            });
    }, [props.property.PropertyID])

    useEffect(() => {
        _fetchConstructionInvestment();
    }, [_fetchConstructionInvestment]);

    const onUploadStatusChange = (e: UploadOnStatusChangeEvent) => {
        setFiles(e.newState);

        if (e.response?.status === 200) {
            if (e.response.response.Success) {
                SetShowNewMessageWindow(false);
                _fetchConstructionInvestment();
                setForceUpload(false);
            } else {
                if (window.confirm(e.response.response.ErrorMessage)) {
                    setForceUpload(true);
                    setFiles(files => files.map(x => {
                        x.status = 2;
                        return x;
                    }));
                    setTimeout(() => {
                        uploadRef.current?.triggerUpload();
                    }, 100);
                } else {
                    setFiles(files => files.map(x => {
                        x.status = 0;
                        return x;
                    }));
                }
            }
        }
        if (e.response) {
            //setIsUploadLoading(false);
            if (e.response.response === null) {
                //if (e.response.request.responseURL.indexOf("/AccessDenied?") > -1) {
                //    alert("You do not have permissions.  Please see admin.");
                //} else if (e.response.status !== 200) {
                //    alert("An unexpected error has occurred");
                //}
                alert("An unexpected error has occurred");
                return;
            }
        }
    }

    const DrawConstructionBody = (item: ConstructionPostViewModel) => {
        switch (item.Type) {
            case 0: //text
                return <CardSubtitle>{item.Body}</CardSubtitle>
            case 1: //url
                return <div>
                    {item.Body && item.Body.length > 0 && <CardSubtitle>{item.Body}</CardSubtitle>}
                    <a
                        href={item.Url}
                        rel="noreferrer"
                        title="Unassign Agent?"
                        target="_blank"
                    >
                        {item.Url}
                    </a>
                </div>
            case 2:
                return (<div>
                    {item.Body && item.Body.length > 0 && <CardSubtitle>{item.Body}</CardSubtitle>}
                    <img
                        style={{ width: 412, height: 284 }}
                        className="img"
                        src={`/api/Property/ViewConstructionImage/${item.ID}?token=${localStorage.getItem('ACCESS_TOKEN')}`}
                        //src={item.Url}
                        alt={''}
                    /></div>);
            case 3:
                //return 1;
                return <div>
                    {item.Body && item.Body.length > 0 && <CardSubtitle>{item.Body}</CardSubtitle>}
                    <ScrollView
                        style={{ width: 412, height: 284 }}
                        pagerOverlay={'dark'}
                        endless={true}
                        automaticViewChange={false}
                    >
                        {item.Children.map((child, index) => {
                            return (
                                <div className="image-with-text" key={index}>
                                    <CardHeader className="pt-1 pb-1 font-italic fw-dark" style={{ paddingLeft: 6 }}>Showing image {index + 1} of {item.Children.length}</CardHeader>
                                    <img
                                        src={`/api/Property/ViewConstructionImage/${child.ID}?token=${localStorage.getItem('ACCESS_TOKEN')}`}
                                        alt={``}
                                        style={{ width: 512, height: 384 }}
                                        draggable={false}
                                    />
                                </div>
                            );
                        })}
                    </ScrollView></div>
        }
        return item.Type;
    }

    const handleChange = (e: StepProps) => {
        setConstructionStartedOn(fetchConstructionStatusDateTime(e.value));
        setStepperValue(e.value);
        setConfirmDialog(true);
    };

    const _updateConstructionStatus = (acceptChange: boolean) => {
        if (!acceptChange) {
            setStepperValue(currentStepperValue);
        } else {
            setCurrentStepperValue(stepperValue);

            const data = {
                PropertyID: props.property.PropertyID,
                ConstructionStatus: stepperValue * 10,
                StartedDateTime: Moment(Moment(constructionStartedOn).utc().format('MM/DD/YYYY')).toDate()
            };
            fetchApi('/api/Property/UpdateConstructionStatus', data, 'POST')
                .then((response: { Success: boolean, ErrorMessage: string }) => {
                    //setLoading(false);
                    if (response.Success) {
                        _fetchConstructionInvestment();
                    } else {
                        alert(response.ErrorMessage);
                    }
                })
                .catch(() => {
                    //setLoading(false);
                    alert("Unable to save");
                });
        }
    }

    const _saveNote = () => {
        const data = {
            PropertyID: props.property.PropertyID,
            Note: message.value,
            Link: link.value
        };
        fetchApi('/api/Property/UpdateConstructionNote', data, 'POST')
            .then((response: { Success: boolean, ErrorMessage: string }) => {
                //setLoading(false);
                if (response.Success) {
                    _fetchConstructionInvestment();
                } else {
                    alert(response.ErrorMessage);
                }
            })
            .catch(() => {
                //setLoading(false);
                alert("Unable to save");
            });
    }

    const _deletePost = (id: number) => {
        const data = {
            PropertyID: props.property.PropertyID,
            PropertyConstructionPostID: id
        };
        fetchApi('/api/Property/DeleteConstructionPost', data, 'POST')
            .then((response: { Success: boolean, ErrorMessage: string }) => {
                if (response.Success) {
                    _fetchConstructionInvestment();
                } else {
                    alert(response.ErrorMessage);
                }
            });
    }

    const fetchConstructionStatusDateTime = (index: number) => {
        var r = constructionInvestmentViewModel?.ConstructionStatusHistory.find(x => x.Status === (index * 10));
        if (r)
            return Moment.utc(r.StatusDateTime).local().toDate();
        return new Date(Date.now());
    };

    const fetchConstructionStatusLabel = (index: number) => {
        var r = constructionInvestmentViewModel?.ConstructionStatusHistory.find(x => x.Status === (index * 10));
        if (r)
            return Moment.utc(r.StatusDateTime).local().format("MM/DD/YYYY");
        return "";
    };

    const itemDisplayOverride = (props: StepProps) => {
        return (
            <Step {...props} onClick={() => handleChange(props)}>
                <span className="k-step-indicator" aria-hidden="true">
                    <span className={props.icon} />
                </span>
                <span className="k-step-label">
                    <span className="k-step-text">{props.label}</span>
                    <span className="fw-normal">{fetchConstructionStatusLabel(props.value)}</span>
                </span>
            </Step>
        );
    };

    return (
        <>
            <div className="container-fluid">
                <p className="property-panel-title">Construction Investor Updates</p>
                <p className="fw-bold h6">Current Status: <i>{constructionInvestmentViewModel?.StatusName}</i></p>
                <div className="row mt-4">
                    <div className="col-lg-3">
                        <Stepper
                            value={items.find(x => x.value === stepperValue)?.index as number}
                            items={items.map((item, index) => {
                                return {
                                    ...item,
                                    current: stepperValue === item.value,
                                }
                            })}
                            item={itemDisplayOverride}
                            orientation={"vertical"}
                        />
                    </div>
                    <div className="col-lg-9" style={{ maxHeight: 750, overflowY: 'auto' }}>
                        <form className="k-form k-form-md p-0">

                            <div className="text-start">
                                <Button type="button" themeColor="primary" onClick={() => { SetShowNewMessageWindow(!showNewMessageWindow); }}>Add New Construction Details</Button>
                            </div>

                            <div className="mt-3">
                                <div className="k-card-list">
                                    {constructionInvestmentViewModel?.Posts.map((item, index) =>
                                        <Card key={index}>
                                            {(item.Type === 0 || item.Type === 1) && <CardHeader className="pt-1 pb-1 font-normal fw-dark">Note</CardHeader>}
                                            {item.Type === 2 && <CardHeader className="pt-1 pb-1 font-normal fw-dark">Picture</CardHeader>}
                                            {item.Type === 3 && <CardHeader className="pt-1 pb-1 font-normal fw-dark">Picture Album</CardHeader>}
                                            <CardBody>
                                                {DrawConstructionBody(item)}
                                            </CardBody>
                                            <CardActions
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Button
                                                    fillMode="link"
                                                    themeColor="error"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        if (window.confirm("Are you sure you want to delete this post?")) {
                                                            _deletePost(item.ID);
                                                        }
                                                    }}
                                                >
                                                    DELETE
                                                </Button>
                                                <span
                                                    style={{
                                                        fontSize: "13px",
                                                        alignSelf: "center",
                                                        color: "#656565",
                                                    }}
                                                >
                                                    {Moment.utc(item.PostedDateTime).local().fromNow()}
                                                </span>
                                            </CardActions>
                                        </Card>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {showNewMessageWindow && <Dialog
                title="New Construction Details"
                //modal
                onClose={() => SetShowNewMessageWindow(false)}
                max-width={Math.min(window.innerWidth, 480)}
                width={480}
            //height={650}
            //initialWidth={Math.min(window.innerWidth, 600)} initialHeight={650}
            //top={(buttonRef.current?.getBoundingClientRect().top ?? 325) - 325}
            >
                <form className="k-form k-form-md p-0">

                    <FieldWrapper>
                        <DropDownList
                            data={["Select an option...", "Add Note", "Add Pictures"]}
                            value={statusUpdateDropDownValue}
                            onChange={(e) => { setStatusUpdateDropDownValue(e.value) }}
                        />
                    </FieldWrapper>

                    {statusUpdateDropDownValue === 'Add Note' && <>
                        <FieldWrapper>
                            <Label>Note/Construction Details</Label>
                            <TextArea
                                autoFocus={true}
                                required
                                rows={4}
                                value={message.value}
                                onChange={(e) => setMessage({ value: e.value as string || '', error: '' })}
                            />
                            {/*<Error>{estimatedSplit.error}</Error>*/}
                        </FieldWrapper>

                        <FieldWrapper>
                            <Label>Website Link</Label>
                            <Input
                                placeholder={'https://... (optional)'}
                                maxLength={2083}
                                value={link.value}
                                onChange={(e) => setLink({ value: e.value || '', error: '' })}
                            />
                            {/* <Error>{listingLink.error}</Error>*/}
                        </FieldWrapper>
                    </>}

                    {statusUpdateDropDownValue === 'Add Pictures' && <>
                        <FieldWrapper>
                            <Label>Picture/Album Caption</Label>
                            <Input
                                placeholder={'Can be left blank...'}
                                maxLength={200}
                                value={pictureCaption.value}
                                onChange={(e) => setPictureCaption({ value: e.value || '', error: '' })}
                            // onChange={(e) => setListingLink({ value: e.value || '', error: '' })}
                            />
                            {/* <Error>{listingLink.error}</Error>*/}
                        </FieldWrapper>

                        <FieldWrapper>
                            <Label>Picture/Album Upload</Label>
                            <Upload
                                ref={uploadRef}
                                batch={true}
                                multiple={true}
                                autoUpload={false}
                                defaultFiles={[]}
                                restrictions={{
                                    allowedExtensions: ['.jpg', '.jpeg', '.png', '.gif'],
                                    maxFileSize: 10000000 // 10 MB
                                }}
                                saveUrl={`/api/Property/UploadConstructionImage/${props.property.PropertyID}`}
                                saveField="File"
                                saveMethod="POST"
                                onBeforeUpload={(e: UploadOnBeforeUploadEvent) => {
                                    e.additionalData.Caption = pictureCaption.value;
                                    e.additionalData.Force = forceUpload;
                                }}
                                onStatusChange={onUploadStatusChange}
                                withCredentials={false}
                                saveHeaders={{
                                    'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                                }}
                                files={files}
                                onAdd={(e) => setFiles(e.newState)}
                                onRemove={(e) => setFiles(e.newState)}
                                onProgress={(e) => setFiles(e.newState)}

                            />
                            <p className="p-1"><small>
                                File types supported: .jpg, .jpeg, .png, .gif
                            </small>
                            </p>
                        </FieldWrapper>
                    </>}
                </form>
                {statusUpdateDropDownValue === 'Add Note' &&
                    <DialogActionsBar>
                        <div className="text-end">
                            <Button themeColor="primary" onClick={() => { SetShowNewMessageWindow(!showNewMessageWindow); _saveNote(); }} disabled={
                                message.value.length === 0
                            }>Save Details</Button>
                        </div>
                    </DialogActionsBar>
                }


            </Dialog>}
            {
                confirmDialog && <Dialog title='Construction Status Update' onClose={() => {
                    setConfirmDialog(false);
                    _updateConstructionStatus(false);
                }}>
                    <div style={{ margin: "25px" }}>
                        <span>Change construction status to&nbsp;
                            <span className='fw-bold fst-italic'>{items.find(x => x.value === stepperValue)?.label?.toLowerCase()}</span>?
                        </span>

                    </div>
                    <div style={{ margin: "25px" }}>
                        <FieldWrapper>
                            <Label>Started On</Label>
                            <DatePicker required value={constructionStartedOn} max={new Date(Date.now())} onChange={(e) => { setConstructionStartedOn(e.value); }} />
                        </FieldWrapper>
                    </div>
                    <DialogActionsBar>
                        <Button onClick={() => {
                            setConfirmDialog(false);
                            _updateConstructionStatus(false);
                        }}>CANCEL</Button>
                        <Button themeColor="primary" onClick={() => {
                            setConfirmDialog(false);
                            _updateConstructionStatus(true);
                        }}>OK</Button>
                    </DialogActionsBar>
                </Dialog>
            }

        </>
    );
}

export default PropertyConstruction;


