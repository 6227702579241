import { AddressViewModel } from "../types/address";
import { IDName } from '../utils/generic';

export enum InvestorType {
  B52,
  RyansPeople,
  NoPaymentNoInvestment,
  PreviousInvestor,
  BIGGroup,
  PaidNoInvestment
}

export type InvestorViewModel = {
  InvestorID: number;
  StripeCustomerID: string;
  FullName: string;
  FirstName: string;
  LastName: string;
  VestingName: string;
  InvestorTypeDesc: string;
  InvestorType: InvestorType;
  Email: string;
  Email2: string;
  PhoneNumber: string;
  PhoneNumber2: string;
  Address: AddressViewModel;
  Active: boolean;
  MaxInvestmentAmount: number;
  SignupFeePaid: boolean;
  IsEmployee: boolean;
  HowYouHeard: string;
  AppReferralID: null | number;
  CreatedDateTime: Date;
}

export function getInvestorType(investorType: InvestorType): string {
  switch (investorType) {
      case InvestorType.B52:
          return "B52 Investor";
      case InvestorType.RyansPeople:
          return "Ryan's People";
      case InvestorType.NoPaymentNoInvestment:
          return "No Payment/No Investment";
      case InvestorType.PreviousInvestor:
          return "Previous Investor";
      case InvestorType.BIGGroup:
          return "BIG Group";
      case InvestorType.PaidNoInvestment:
          return "Paid/No Investment";
      default:
          return InvestorType[investorType];
  }
}

export function getInvestorTypes() {
  const investorTypeOptions: IDName[] = [];
  for (var enumMember in InvestorType) {
    const num = Number(enumMember);
    if (!isNaN(num)) {
        investorTypeOptions.push({ ID: num, Name: getInvestorType(num) });
    }
  }
  return investorTypeOptions;
}
