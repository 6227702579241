import { Message } from "@progress/kendo-react-conversational-ui";
import { ProjectSummary } from "./Projects";

export const exampleProjectData: ProjectSummary[] = [
  {
    ConstructionProjectID: 1,
    Name: '1725 Mount Olympus',
    Active: true,
    ScopeOfWork: 'Complete remodel of the kitchen, and grounds.'
  },
  {
    ConstructionProjectID: 2,
    Name: '1234 Main St',
    Active: true,
    ScopeOfWork: 'Minor repairs to the roof.'
  },
  {
    ConstructionProjectID: 3,
    Name: '9876 1st St',
    Active: false,
    ScopeOfWork: 'Full remodel of the bathrooms.'
  }
]

export const chatUser = {
  id: 1,
  avatarUrl: "https://demos.telerik.com/kendo-ui/content/web/Customers/RICSU.jpg",
  avatarAltText: "RICSU"
};

export const chatInitialMessages: Message[] = [
  {
    author: { id: 0 },
    timestamp: new Date(2024, 1, 12, 8, 30, 0),
    text: "Hello, This is the Belwood Investments, you've been assigned to the 1725 Mount Olympus project. Can you please provide an update on the status of the project?"
  },
  {
    author: chatUser,
    timestamp: new Date(2024, 1, 12, 8, 35, 0),
    attachments: [
      {
        contentType: "checkin",
        content: "1725 Mount Olympus Dr"
      }
    ]
  },
  {
    author: chatUser,
    timestamp: new Date(2024, 1, 12, 8, 35, 10),
    text: "I'm currently working on the electrical wiring of the kitchen. I should be done by the end of the week."
  },
  {
    author: { id: 0 },
    timestamp: new Date(2024, 1, 12, 8, 40, 0),
    text: "Thank you for the update. As a reminder, please be sure to check out of the project when you leave the site."
  },
  {
    author: chatUser,
    timestamp: new Date(2024, 1, 12, 14, 25, 0),
    text: "Here are some photos of the progress I've made so far.",
    attachments: [
      {
        title: "Mystique",
        contentType: "image/png",
        content: "https://demos.telerik.com/kendo-ui/content/chat/Mystique.png"
      },
      {
        title: "Rimondi",
        contentType: "image/png",
        content: "https://demos.telerik.com/kendo-ui/content/chat/Rimondi.png"
      },
      {
        title: "Amanzoe",
        contentType: "image/png",
        content: "https://demos.telerik.com/kendo-ui/content/chat/Amanzoe.png"
      },
    ],
    attachmentLayout: "list",
  },
  {
    author: chatUser,
    timestamp: new Date(2024, 1, 12, 14, 25, 40),
    attachments: [
      {
        contentType: "checkout",
        content: "1725 Mount Olympus Dr"
      }
    ]
  }
];